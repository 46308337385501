import React from "react";
import CardLink from "./card.link";
import { CardLinkTwoIllus } from "./card-link-two-illus";
import type { SanityImageAssetDocument } from "@sanity/client";

// Partial type, see /studio/schemas/single.js for all available fields
export type SinglePageProps = {
  _type?: string;
  title: string;
  description: string;
  illustration: {
    asset: SanityImageAssetDocument;
  };
  frontPageIllustrations: {
    asset: SanityImageAssetDocument[];
  };
};

interface CardListLinkProps {
  entries: SinglePageProps[];
  type: string;
}

export const CardListLink = ({
  entries = [],
  type = "",
}: CardListLinkProps) => {
  return (
    <div className="c-card-link-two-illus-container">
      <div className="o-card-listing">
        {!entries.find(({ _type = "" }) =>
          ["single", "scienceArticleFrontPage", "schoolAndEducation"].includes(
            _type
          )
        )
          ? entries.map(entry => (
              <CardLink entry={entry} key={entry.title} type={type} />
            ))
          : entries.map(entry => (
              <CardLinkTwoIllus entry={entry} key={entry.title} />
            ))}
      </div>
    </div>
  );
};
