import Head from "next/head";
import { urlFor } from "../../helpers/urlFor";
import { useRouter } from "next/router";

const figureOutFallbackOrder = ({
  og_title,
  og_description,
  title,
  lede,
  image,
  fallbackImage,
  og_image,
}) => {
  const returnTitle = og_title || title;

  const returnDescription = og_description || lede;
  const returnImage =
    og_image && og_image.asset
      ? og_image
      : image && image.asset
        ? image
        : fallbackImage;

  return {
    title: returnTitle,
    description: returnDescription,
    image: returnImage,
  };
};

type Props = {
  doc?: {
    title: string;
    lede?: string;
    image?: {
      _type: string;
      alt: string;
      asset: Sanity.ImageAsset;
    };
    illustration?: {
      _type: string;
      alt: string;
      asset: Sanity.ImageAsset;
    };
    metaTags?: {
      og_title?: string;
      og_description?: string;
      og_image?: string;
    };
  };
  settings?: {
    image: Sanity.ImageAsset;
  };
};

/**
 * Purpose: Take a Sanity document and return correct meta tags.
 * Sources:
 * https://css-tricks.com/essential-meta-tags-social-media/
 */
export default function PageMeta({ doc, settings }: Props) {
  const router = useRouter();
  const {
    title: docTitle,
    lede,
    image: docImage,
    illustration,
    metaTags: { og_title = "", og_description = "", og_image = "" } = {},
  } = doc || {};
  const { image: fallbackImage } = settings || {};
  const { pathname: url } = router;

  const { title, description, image } = figureOutFallbackOrder({
    og_title,
    og_description,
    title: docTitle,
    lede,
    image: docImage,
    fallbackImage,
    og_image,
  });

  const imageUrl =
    image && image.asset ? urlFor(image).size(1200, 630).url() : undefined;
  const illustrationUrl =
    illustration && illustration.asset
      ? urlFor(illustration).size(1200, 630).url()
      : undefined;
  return (
    <Head>
      {title && <title>{`${title} - REN`}</title>}
      <meta property="og:site_name" content="REN"></meta>
      {title && <meta property="og:title" content={title} />}
      {description && (
        <meta
          name="description"
          property="og:description"
          content={description}
        />
      )}
      {url && <meta property="og:url" content={url} />}
      {(imageUrl || illustrationUrl) && (
        <>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta property="og:image" content={imageUrl || illustrationUrl} />
          {imageUrl && (
            <meta name="twitter:image:alt" content={image.caption} />
          )}
        </>
      )}
    </Head>
  );
}
