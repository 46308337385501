import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import Image from "../media/image";
import type { SinglePageProps } from "./card-list-link";

export const CardLinkTwoIllus = ({ entry }: { entry: SinglePageProps }) => {
  const { title, description, frontPageIllustrations } = entry;
  return (
    <RenLink href={entry} classes="o-card-listing__item c-card-link--listing">
      <div className="c-card-link-two-illus u-bg--white hover-focus">
        {title && <h3 className="c-card-link-two-illus__title">{title}</h3>}
        {description && <p>{description}</p>}
        <div className="c-card-link-two-illus__illu-item">
          {frontPageIllustrations && frontPageIllustrations[1] && (
            <Image
              image={frontPageIllustrations[1].asset}
              alt={frontPageIllustrations[1].asset.alt}
              className="c-card-link-two-illus__image"
            />
          )}

          {frontPageIllustrations && frontPageIllustrations[0] && (
            <Image
              image={frontPageIllustrations[0].asset}
              alt={frontPageIllustrations[0].asset.alt}
              className="c-card-link-two-illus__image"
            />
          )}
        </div>
        <div className="c-card-link-two-illus__arrow">
          <ArrowIcon />
        </div>
      </div>
    </RenLink>
  );
};
