import { createClient } from "@sanity/client";

export const defaultConfig = {
  projectId: process.env.NEXT_PUBLIC_SANITY_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: "2021-04-16",
  useCdn: false, // `false` if you want to ensure fresh data
  token: process.env.SANITY_AUTH_TOKEN,
};

export const buildClient = (override = {}) => {
  return upgradeClient(createClient({ ...defaultConfig, ...override }));
};

// Default client
export const client = upgradeClient(createClient(defaultConfig));

// Improve the default fetch() so that we can query with JS objects, as well as strings. This
// is needed by the useSanityPreview hook.
function upgradeClient(client) {
  client.oldSanityFetch = client.fetch;
  client.fetch = (query: string | object, params) => {
    return client.oldSanityFetch(parseSanityQuery(query), params);
  };
  return client;
}

/**
 * Take any plain JS objects and convert to a combined,
 * stringified Sanity query.
 */
export function parseSanityQuery(val) {
  if (val !== null && typeof val === "object") {
    return `{ ${Object.entries(val)
      .map(([key, value]) => {
        return `"${key}": ${value}`;
      })
      .join(", ")} }`;
  }
  return val;
}
