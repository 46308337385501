import RenLink from "./RenLink";
import { Fragment } from "react";
import { SanityImageAssetDocument } from "@sanity/client";
import { prettyDate } from "../../helpers/dates";
import ArrowIcon from "../icons/arrow.icon";
import Image from "../media/image";
import { PickAndDisplayAd } from "../portableText/advertisement";
import { limitText } from "../../helpers/limitText";

interface BylineType {
  publishDate: string;
  name?: string;
  title?: string;
}

interface ArticleEntry {
  title: string;
  lede: string;
  _id: string;
  image?: {
    asset: SanityImageAssetDocument;
    alt?: string;
    caption?: string;
  };
  byline?: BylineType;
}

interface AdEntry {
  adLink: string;
  image: {
    asset: SanityImageAssetDocument;
    alt?: string;
  };
}

const ArticleMosaicWithAds = ({
  entries,
  ads,
}: {
  entries: ArticleEntry[];
  ads?: AdEntry[];
  interval?: number;
}) => {
  return (
    <div className="c-article-mosaic">
      {entries.map((entry, index) => (
        <Fragment key={index}>
          <ArticleMosaicAdvertisement
            index={index}
            ads={ads}
            key={"ad-${index}"}
          />
          <ArticleMosaicItem entry={entry as ArticleEntry} key={entry._id} />{" "}
        </Fragment>
      ))}
    </div>
  );
};

const ArticleMosaicAdvertisement = ({ ads, index }) => {
  if (index == 0 || index % 5 != 0) {
    return null;
  }
  return (
    <div className={index % 10 == 0 ? "c-advertisement--wide" : ""}>
      <PickAndDisplayAd ads={ads} key={"ad-${index}"} wide={index % 10 == 0} />
    </div>
  );
};

const ArticleMosaicItem = ({ entry }: { entry: ArticleEntry }) => (
  <RenLink href={entry} classes="c-article-mosaic-item hover-focus">
    {entry.image && entry.image.asset && (
      <Image
        className="c-article-mosaic-item__image"
        image={entry.image.asset}
        alt={""}
        interceptBuilder={builder => builder.width(600).height(400)}
      />
    )}
    <div className="c-article-mosaic-item__content">
      <div className="c-article-mosaic-item--ie11">
        {entry.byline && entry.byline.publishDate && (
          <p className="c-article-mosaic-item__datetag">
            {prettyDate({ date: entry.byline.publishDate })}
          </p>
        )}
        <h3 className="c-article-mosaic-item__title">{entry.title}</h3>
        <p className="c-article-mosaic-item__lede">
          {limitText(entry.lede, 35)}
        </p>
        {!(entry.image && entry.image.asset) && (
          <p>{limitText(entry.lede, 35)}</p>
        )}
      </div>
      <div className="c-article-mosaic-item__arrow">
        <ArrowIcon />
      </div>
    </div>
  </RenLink>
);

export default ArticleMosaicWithAds;
