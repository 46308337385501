import { format } from "date-fns";
import { nb } from "date-fns/locale";
const jstz = require("jstimezonedetect");

function getTimeZone() {
  return jstz.determine().name();
}

function prettyDate({
  date,
  dateFormat = "d. MMMM yyyy",
  modifyFormatInTimeZone = false,
}) {
  let defaultString = "d. MMMM yyyy";
  if (modifyFormatInTimeZone && jstz.determine().name() !== "Europe/Oslo") {
    dateFormat = (dateFormat || defaultString).concat(" (O)");
  }
  const formatString = dateFormat || defaultString;
  const formatedDate = format(new Date(date), formatString, { locale: nb });
  return formatedDate;
}

function prettyTime(date) {
  let defaultFormat = "HH:mm";
  const formatedTime = format(new Date(date), defaultFormat, { locale: nb });
  return formatedTime;
}

function sortByByline(entries = []) {
  entries.sort(
    (a, b) =>
      new Date(b.byline && b.byline.publishDate) -
      new Date(a.byline && a.byline.publishDate)
  );
}

function isSameDay(first, second) {
  first = new Date(first);
  second = new Date(second);
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

export { prettyTime, prettyDate, sortByByline, isSameDay, getTimeZone };
