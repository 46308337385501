import { SanityImageAssetDocument } from "@sanity/client";
import { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import { urlFor } from "helpers/urlFor";

type Props = {
  image: SanityImageAssetDocument;
  interceptBuilder?: (builder: ImageUrlBuilder) => ImageUrlBuilder;
  className?: string;
  alt: string;
  height?: string;
  width?: string;
};

const Image = (props: Props) => {
  const { image, interceptBuilder, className = "", alt, ...rest } = props;

  // Return if there is no image
  if (!(image?.asset || image?.assetId)) {
    if (image._type !== "reference" && !image._ref) {
      return null;
    } // Why? Images from rich text editor is not passed as sanity assets. rich-image.tsx
  }

  const trimmedAlt = alt?.trim() || "";
  const roleProps = trimmedAlt === "" ? { role: "presentation" } : {};

  const configuredBuilder = interceptBuilder
    ? // Let callers configure the url builder
      interceptBuilder(urlFor(image))
    : // By default set a max size
      urlFor(image).width(1200);

  return (
    // TODO: Disabled eslint warning for now, but we
    // should consider enabling if or what advantages it gives us.
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={className}
      alt={trimmedAlt}
      src={configuredBuilder.auto("format").quality(70).sharpen(20).url()}
      {...roleProps}
      {...rest}
    />
  );
};

export default Image;
