import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import { prettyDate, isSameDay } from "../../helpers/dates";
import PropTypes from "prop-types";

const CardMinor = ({ index, category, place, date, entry }) => {
  var green = entry._type == "tool" || entry._type == "conference";

  return (
    <RenLink href={entry}>
      <div
        className={
          "c-card-minor o-card-listing__item" +
          (index % 2 != 0 ? " u-bg--lightgrey" : " u-bg--white") + " hover-focus"
        }
        aria-label={entry.title}
      >
        <div className="c-card-minor--flex">
          <div>
            {green && <figure className="c-card-minor__top-bar--green" />}
            {(entry._type == "renbladCollection" ||
              entry._type == "userGuide") && (
              <figure className="c-card-minor__top-bar--yellow" />
            )}
            {entry.series && (
              <p className="o-label-uppercase u-text--grey o-grid__item--margin-top u-text--black">
                {entry.series}
              </p>
            )}
            {category && (
              <p className="o-label-uppercase u-text--grey o-grid__item--margin-top u-text--black">
                {category}
              </p>
            )}
            {entry.eventType && (
              <p className="o-label-uppercase u-text--grey o-grid__item--margin-top u-text--black">
                {entry.eventType}
              </p>
            )}

            <h3
              className={
                entry._type == "conference"
                  ? "c-card-minor__header c-card-minor__header--bold"
                  : "c-card-minor__header"
              }
            >
              {entry.title}
            </h3>
            {entry.keywords && entry._type != "conference" && (
              <ul
                className={
                  green
                    ? "c-card-minor__list c-card-minor__list--green"
                    : "c-card-minor__list"
                }
              >
                {entry.keywords.map(keyword => (
                  <li key={keyword}>{keyword}</li>
                ))}
              </ul>
            )}
            {(entry.courseLocation || entry.courseDate) && (
              <div>
                <p className="o-label-uppercase u-text--grey">Tid og sted</p>
                <p className="c-card-minor__date u-text--black">
                  {entry.courseDate && (
                    <>
                      <time dateTime={entry.courseDate}>
                        {prettyDate({
                          date: entry.courseDate,
                          dateFormat: "dd. MMMM",
                        })}
                      </time>
                    </>
                  )}
                  {entry.endDate &&
                    entry.courseDate &&
                    !isSameDay(entry.courseDate, entry.endDate) && (
                      <time dateTime={entry.endDate}>
                        {" "}
                        -{" "}
                        {prettyDate({
                          date: entry.endDate,
                          dateFormat: "dd. MMMM yyyy",
                        })}
                      </time>
                    )}
                </p>
                <p className="c-card-minor__place u-text--black">
                  {entry.courseLocation && entry.courseLocation}
                </p>
              </div>
            )}
          </div>

          <div className="o-grid o-grid__center o-grid__end c-card-minor__arrow">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </RenLink>
  );
};

CardMinor.propTypes = {
  index: PropTypes.number,
  category: PropTypes.string,
  place: PropTypes.string,
  date: PropTypes.string,
  entry: PropTypes.object,
};

export default CardMinor;
